import React, {useState} from 'react';
import mutlog from '../Guarantees/mutlog.png';
import afiesca from './afiesca.png';
import {Col, Row} from "react-grid-system";
import InstallmentOffre from "../../components/Installments/installment_offre";
import {GiMagnifyingGlass} from 'react-icons/gi';
import {TiTick} from 'react-icons/ti';
import {payment_periods} from '../../js/helpers/data'
import Guarantees from "../../components/Guarantees/bank_guarantees";
import ExtraGuarantees from "../../components/Guarantees/extra_guarantees";
import mncap from './mncap.png';
import {parseInstallments, toFrenchNumber} from "../../js/helpers/functions";
import {isMobile} from 'react-device-detect';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AVENIRNAOASSUR from './AVENIRNAOASSUR.png';
import SEREVI3 from './serenity.png';
import IRIADE from './IRIADE.jpg';
import CNP2 from './SCR-20230308-em1.png'
import MAESTRO from './maestro.png';
import MILTIS from './sopretis.png';
import IPTIQ from './iptiq.png'
import Translate from "components/CustomTranslate";
import StandardButton from "../Form/StandardButton";

export default function InsurerCard(props) {
	const [openTable, setOpenTable]           = useState(false);
	const [openGuaranties, setOpenGuaranties] = useState(false);
	const logos                               = {mutlog, 'afi-esca': afiesca, mncap, AVENIRNAOASSUR, MAESTRO, IRIADE, MILTIS,IPTIQ, MNCAP: mncap, SEREVI3, CNP2};
	const insurance_cost                      = props.loan.constant_premium ? props.insurer.total.insurance_cost : props.insurer.variable ? props.insurer.variable.insurance_cost : props.insurer.total.insurance_cost;
	const premium                             = props.loan.constant_premium ? props.insurer.total.premium : props.insurer.variable ? props.insurer.variable.premium : props.insurer.total.premium;
	const ins                                 = props.loan.constant_premium ? props.insurer : props.insurer.variable ? props.insurer.variable : props.insurer;
	let installments                          = parseInstallments(ins);
	let button                                = props.insurer;
	let eight_year_cost                       = props.loan.constant_premium ? props.insurer.total.eight_year_cost : props.insurer.variable ? props.insurer.variable.eight_year_cost : props.insurer.total.eight_year_cost;
	const average                             = installments.reduce((total, next) => parseFloat(total.toString().indexOf(' ') !== -1 ? total.replace(' ', '') : total) + parseFloat(next.total.toString().indexOf(' ') !== -1 ? next.total.replace(' ', '') : next.total), 0) / installments.length;
	const openTableFunc                       = () => {
		if (openGuaranties == true) {
			setOpenGuaranties(false)
		}
		setOpenTable(!openTable)
	}

	const openGuarantiesFunc = () => {
		if (openTable == true) {
			setOpenTable(false)
		}
		setOpenGuaranties(!openGuaranties)
	}

	return (
		<Row justify="center">

			<Col md={10}
			     onClick={props.onClick}
			     className='incomeRow'
			     style={{
				     paddingTop: '40px',
			     }}>

				<Row justify='center' style={{marginBottom: '30px'}}>
					<Col md={8}>

						<Row>
							<Col md={10} xs={8}>
								<img style={{float: 'left'}} width="200" src={logos[props.flow]}/>
							</Col>
							{/*{props.insurer.total.savings &&*/}
							{/*	<Col md={2} xs={4}>*/}
							{/*		<div style={{width: '65px', height: '65px', backgroundColor: '#60bcd0', display: 'flex', borderRadius: 65, fontSize: '18px', fontWeight: '500', color: '#fff', justifyContent: 'center', alignItems: 'center'}}>*/}
							{/*			- {props.insurer.total.savings}%*/}
							{/*		</div>*/}
							{/*	</Col>}*/}
						</Row>
					</Col>

				</Row>
				<Row justify="center">
					<Col md={2} xs={6}>
						<span className={isMobile ? 'insurerTitleMobile' : 'insurerTitle'}><Translate value="step3.cost8"/></span>
						<p style={{color: props.config.theme.secondary}}
						   className={!isMobile ? 'insurer-card-number' : 'insurer-card-number-mobile'}>{toFrenchNumber(eight_year_cost)}<Translate value="generic.currencySymbol"
						                                                                                                                            dangerousHTML/></p>
					</Col>
					<Col md={2} xs={6}>
						<span className={isMobile ? 'insurerTitleMobile' : 'insurerTitle'}><Translate value="step3.costGlobal"/></span>
						<p style={{color: props.config.theme.secondary}} className={!isMobile ? 'insurer-card-number' : 'insurer-card-number-mobile'}>{toFrenchNumber(insurance_cost)}<Translate
							value="generic.currencySymbol" dangerousHTML/></p>
					</Col>
					<Col md={2} xs={6}>
						<span className={isMobile ? 'insurerTitleMobile' : 'insurerTitle'}>Coût {payment_periods[props.payment_period || "MENSUELLE"]}</span>
						<p style={{color: props.config.theme.secondary}}
						   className={!isMobile ? 'insurer-card-number' : 'insurer-card-number-mobile'}>{toFrenchNumber(props.loan.constant_premium ? premium : average)}<Translate
							value="generic.currencySymbol" dangerousHTML/></p>
					</Col>
					<Col md={2} xs={6}>
						<span className={isMobile ? 'insurerTitleMobile' : 'insurerTitle'}><Translate value="step3.tax"/></span>
						<p style={{color: props.config.theme.secondary}}
						   className={!isMobile ? 'insurer-card-number' : 'insurer-card-number-mobile'}>{parseFloat((props.loan.constant_premium ? props.insurer.total.insurance_rate : props.insurer.variable.insurance_rate) * 100).toFixed(3)}%</p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={3}>
						<StandardButton onClick={() => openTableFunc()}
						                additionalStyle={{background: props.config.theme.button}}
						                className="comparisonButton" value={<Translate value="step3.installments"/>}><GiMagnifyingGlass/></StandardButton>
					</Col>
					<Col md={3}>
						<StandardButton onClick={() => openGuarantiesFunc()}
						                additionalStyle={{background: props.config.theme.button}}
						                className="comparisonButton" value={<Translate value="step3.guarantees"/>}><TiTick/></StandardButton>
					</Col>
					<Col md={3}>
						{props.continueButton}
					</Col>
				</Row>
				{openTable &&
					<div>
						<Row>
							<Col>
								<InstallmentOffre
									type={props.loan.constant_premium ? 'constant' : 'variable'}
									selected='true'
									config={props.config}
									installments={installments}
									payment_period={props.payment_period}
								/>
							</Col>
						</Row>
					</div>
				}
				{openGuaranties &&
					<>
						<Row justify="center">
							<Col>
								<Guarantees translations={props.translations} bank={props.loan.bank} config={props.config} loan={props.loan} borrower={props.borrower}
								            flow={props.loan.flow}/>
							</Col>
						</Row>
					</>
				}
			</Col>
		</Row>
	);
}
